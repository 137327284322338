import { Icon, ModalButton } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import classNames from "classnames";
import { Field, getIn, useFormikContext } from "formik";
import React, { ReactElement, useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import { Button, Collapse } from "react-bootstrap";
import { RapportageSamenstellenState, RapportageStructuurType } from "./infra/rapportage-samenstellen-types";
import { RapportageElementenType, RapportageElementSoort } from "./infra/rapportage-structuur-types";
import classes from "./rapportage-structuur.module.scss";
import { TitelAanpassenModal } from "./titel-aanpassen-modal";

export type RapportageStructuurSamenstellenProps = {
  element: RapportageElementenType;
  parentKey: number;
  parent: string;
  dragableProps?: any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging?: boolean;
  setForceCloseByParrent?: any;
  forceCloseByParrent?: boolean;
  adoptionList?: LabelValuePairs;
};

export const RapportageStructuurElementen = ({
  element,
  parentKey,
  parent,
  dragableProps,
  dragHandleProps,
  isDragging,
  adoptionList
}: RapportageStructuurSamenstellenProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<RapportageSamenstellenState>();

  /* istanbul ignore next reason: Unable to test drag and drop functionality using @testing-library/react. An update for this should be on the roadmap of rtl */
  const handleOnDragEnd = useCallback(
    (result: DropResult): void => {
      const elementName = result.draggableId;
      const destructedElementName = elementName.split(".");

      destructedElementName.pop();

      const parentName = destructedElementName.join(".");
      const parentState = getIn(values, parentName);

      const elementen = parentState.elementen;

      const [reorderedItem] = elementen.splice(result.source.index, 1);
      elementen.splice(result.destination?.index, 0, reorderedItem);

      setFieldValue(`${parentName}.elementen`, elementen);
    },
    [values, setFieldValue]
  );

  const dragAndDropIconPrefab = (): ReactElement => {
    return (
      <span className={classes.drag_n_drop_wrapper} {...dragHandleProps}>
        {parentKey > 0 && <Icon name={"drag"} />}
      </span>
    );
  };

  const spacersPrefab = (): ReactElement[] => {
    return [...new Array(parentKey)].map((_v: undefined, index: number) => {
      return <div key={`${parent}[${index}]`} className={classes.spacer}></div>;
    });
  };

  /* istanbul ignore next */
  const setAllChildrenValuesBasedOnParentReference = (elem: RapportageElementenType): void => {
    elem.elementen.forEach(e => {
      e.geselecteerd = elem.geselecteerd;
      if (e.elementen.length) {
        setAllChildrenValuesBasedOnParentReference(e);
      }
    });
  };

  /* istanbul ignore next */
  const setParentValueBasedOnChildReference = (
    elem: RapportageElementenType | RapportageStructuurType,
    currentPath: string
  ): void => {
    if (!("geselecteerd" in elem)) return;
    const pathArray = currentPath.split(".");
    pathArray.pop();
    const path = pathArray.join(".");
    const parentElem: RapportageElementenType | RapportageStructuurType = getIn(values, path);
    if ("geselecteerd" in parentElem) {
      parentElem.geselecteerd = true;
      setFieldValue(`${path}.geselecteerd`, true);
      setParentValueBasedOnChildReference(parentElem, path);
      return;
    }
  };

  const checkboxPrefab = (): ReactElement => {
    return (
      <span className={classes.checkbox_wrapper}>
        <Field
          onChange={
            /* istanbul ignore next */ () => {
              const parentElementClone: RapportageElementenType = {
                ...getIn(values, parent)
              };
              parentElementClone.geselecteerd = !parentElementClone.geselecteerd;
              setAllChildrenValuesBasedOnParentReference(parentElementClone);

              if (parentElementClone.geselecteerd) {
                setParentValueBasedOnChildReference(parentElementClone, parent);
              }

              setFieldValue(`${parent}`, parentElementClone);
            }
          }
          name={`${parent}.geselecteerd`}
          type={"checkbox"}
        />
      </span>
    );
  };

  const expandButtonPrefab = (): ReactElement => {
    return (
      <span className={classes.expand_wrapper}>
        {/* eslint-disable-next-line react/prop-types */}
        {element.elementen && element.elementen.length > 0 && (
          <Button
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            aria-controls={`table_collapse_${parentKey}`}
            variant={"light"}
            className={classNames(classes.expand_btn, "font-weight-bold")}
            data-testid={`${parent}-expand-btn`}
          >
            {open ? "-" : "+"}
          </Button>
        )}
      </span>
    );
  };

  const editButtonPrefab = (): ReactElement | boolean => {
    return (
      !isDragging &&
      parentKey > 0 && (
        <div className={classes.edit_cell}>
          <ModalButton
            parent={`${parent}`}
            aria-label="Titel aanpassen"
            size="lg"
            content={<Icon name={"pencil"} />}
            data-testid={`${parent}.naam-aanpassen-btn`}
          >
            <TitelAanpassenModal
              parent={parent}
              onSave={(data: any) => {
                setFieldValue("structuren", data.structuren);
              }}
              adoptionList={adoptionList}
            />
          </ModalButton>
        </div>
      )
    );
  };

  return (
    <>
      <div key={parent} style={{ position: "relative" }} {...dragableProps}>
        <div className={classes.main_cell}>
          {dragAndDropIconPrefab()}

          {spacersPrefab()}

          {expandButtonPrefab()}

          {checkboxPrefab()}

          <span className={classNames(classes.label_wrapper, "name-span")} data-testid={`${parent}.naam`}>
            {element.soortElement === RapportageElementSoort.Tekstblok ? `Tekstblok: ${element.naam}` : element.naam}
          </span>
        </div>

        {element.elementen && element.elementen.length > 0 && (
          <Collapse in={open} key={`collapse_${parentKey}`}>
            <div id={`table_collapse_${parentKey}`}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={parent} type={parent} mode={"standard"}>
                  {providedParrent => (
                    <div
                      {...providedParrent.droppableProps}
                      ref={providedParrent.innerRef}
                      onDragCapture={e => e.stopPropagation()}
                    >
                      {element.elementen.map((v: RapportageElementenType, i: number) => {
                        return (
                          <Draggable
                            key={`${parent}.elementen[${i}]`}
                            draggableId={`${parent}.elementen[${i}]`}
                            index={i}
                          >
                            {(providedChild, snapshot) => {
                              const dragHandleProps = getDragHandleProp(providedChild, snapshot, parent, i);

                              return (
                                <div style={{ position: "relative" }}>
                                  <RapportageStructuurElementen
                                    element={v}
                                    key={`${parent}.elementen[${i}]`}
                                    parentKey={parentKey + 1}
                                    parent={`${parent}.elementen[${i}]`}
                                    dragableProps={dragHandleProps}
                                    dragHandleProps={providedChild.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                    adoptionList={adoptionList}
                                  />
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {providedParrent.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Collapse>
        )}

        {editButtonPrefab()}
      </div>
    </>
  );
};

function getDragHandleProp(
  providedChild: DraggableProvided,
  snapshot: DraggableStateSnapshot,
  parent: string,
  i: number
): any {
  const style = {
    ...providedChild.draggableProps.style,
    borderTop: "1px solid var(--darkest-grey)",
    borderRight: "1px solid var(--darkest-grey)",
    backgroundColor: "white",
    paddingTop: "-10px",
    paddingBottom: "10px"
  };

  return {
    ref: providedChild.innerRef,
    ...providedChild.draggableProps,
    style: snapshot.isDragging ? style : providedChild.draggableProps.style,
    "data-testid": `${parent}.elementen[${i}].handle`
  };
}
