import { createMapToDl } from "../../shared/utils/create-map-to-dl";

import {
  kolommenInstellenModalSchema,
  kolommenLastenSchema,
  kolommenNbiSchema,
  rapportageElementSchema,
  rapportageSamenstellenSchema,
  rapportageStructuurSchema
} from "../../shared/components/rapportage/rapportage-samenstellen-schema";
import {
  Rapportage,
  RapportageStructuur,
  RapportageElement,
  RapportageKolomselectie,
  RapportageKolommenNbi,
  RapportageKolommenLasten,
  SoortElementOptions as RapportageElementSoortInstellingen
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import {
  RapportageElementenType,
  RapportageElementSoort
} from "../../shared/components/rapportage/infra/rapportage-structuur-types";

export const mapSoortElement = (soortElement: RapportageElementSoort): RapportageElementSoortInstellingen => {
  switch (soortElement) {
    case RapportageElementSoort.Grafiek:
      return RapportageElementSoortInstellingen.Grafiek;
    case RapportageElementSoort.Hoofdstuk:
      return RapportageElementSoortInstellingen.Hoofdstuk;
    case RapportageElementSoort.Koptekst:
      return RapportageElementSoortInstellingen.Koptekst;
    case RapportageElementSoort.Onderdeel:
      return RapportageElementSoortInstellingen.Onderdeel;
    case RapportageElementSoort.Tabel:
      return RapportageElementSoortInstellingen.Tabel;
    case RapportageElementSoort.Tekstblok:
      return RapportageElementSoortInstellingen.Tekstblok;
  }
};

export const mapRapportageElementToDl: any = createMapToDl(rapportageElementSchema).to<RapportageElement>({
  code: v => v.code,
  elementen: v => v.elementen.map((c: RapportageElementenType) => mapRapportageElementToDl(c)),
  geselecteerd: v => v.geselecteerd,
  naam: v => v.naam,
  soortElement: v => mapSoortElement(v.soortElement),
  volgnummer: v => v.volgnummer,
  inhoud: v => v.inhoud,
  defaultInhoud: v => v.defaultInhoud,
  inhoudWijzigbaar: v => v.perAdviesAanpasbaar
});

export const mapKolommenLastenToDl = createMapToDl(kolommenLastenSchema).to<RapportageKolommenLasten>({
  brutoInkomen: v => v.brutoInkomen,
  brutoLast: v => v.brutoLast,
  fiscaalVoordeel: v => v.fiscaalVoordeel,
  huur: v => v.huur,
  hypotheekAflossing: v => v.hypotheekAflossing,
  kredietLast: v => v.kredietLast,
  nettoLast: v => v.nettoLast,
  opnameUitDepot: v => v.opnameUitDepot,
  overigeUitgaven: v => v.overigeUitgaven,
  rente: v => v.rente,
  somHypotheken: v => v.somHypotheken,
  spaarBeleggingsdepots: v => v.spaarBeleggingsdepots,
  uitkeringKapitaalverzekering: v => v.uitkeringKapitaalverzekering,
  verzekeringspremies: v => v.verzekeringspremies,
  vrh: v => v.vrh,
  waardeopbouw: v => v.waardeopbouw
});

export const mapKolommenNbiToDl = createMapToDl(kolommenNbiSchema).to<RapportageKolommenNbi>({
  brutoLast: v => v.brutoLast,
  hypotheek: v => v.hypotheek,
  inkomstenbelasting: v => v.inkomstenbelasting,
  kredietLast: v => v.kredietLast,
  nbi: v => v.nbi,
  nettoInkomen: v => v.nettoInkomen,
  overigeUitgaven: v => v.overigeUitgaven,
  spaarBeleggingsdepots: v => v.spaarBeleggingsdepots,
  verzekeringen: v => v.verzekeringen,
  vrh: v => v.vrh
});

export const mapKolomSelectieToDl = createMapToDl(kolommenInstellenModalSchema).to<RapportageKolomselectie>({
  kolommenLasten: v => mapKolommenLastenToDl(v.kolommenLasten),
  kolommenNbi: v => mapKolommenNbiToDl(v.kolommenNbi)
});

export const mapRapportageStructuurToDl = createMapToDl(rapportageStructuurSchema).to<RapportageStructuur>({
  elementen: v => v.elementen.map(c => mapRapportageElementToDl(c)),
  kolomselectie: v => mapKolomSelectieToDl(v.kolomselectie),
  naam: v => v.naam,
  standaardRapport: v => v.standaardRapport,
  structuurId: v => v.structuurId,
  volgnummer: v => v.volgnummer,
  isVerwijderbaar: v => v.isVerwijderbaar
});

export const mapRapportageSamenstellenUiToDl = createMapToDl(rapportageSamenstellenSchema).to<Rapportage>({
  structuren: v => v.structuren.map(c => mapRapportageStructuurToDl(c))
});
