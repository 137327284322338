import {
  CheckBox,
  Label,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  RadioInputGroup
} from "adviesbox-shared";
import { FormikContextType } from "formik";
import React, { ReactElement } from "react";
import {
  ControleGewenstNbiOptions,
  ControleGewenstInkomenOptions,
  ControleMaandlastOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { UitgangspuntenState } from "../infra";

type NamePrefix = "werkloosheid" | "pensioen" | "overlijden" | "arbeidsongeschiktheid";
type ControleNormEnGewenstInkomenProps = {
  namePrefix: NamePrefix;
  formik: FormikContextType<UitgangspuntenState>;
};

const ControleNormEnGewenstInkomen = ({
  namePrefix,
  formik: { values }
}: ControleNormEnGewenstInkomenProps): ReactElement => {
  const controleNormTooltip =
    "Hier kan in het scenario de hypotheek- of kredietsom meegenomen worden. Dit is afhankelijk van de gekozen aanleiding en gewenst advies. De hypotheeknorm wordt berekend op basis van de wet Tijdelijke regeling hypothecair krediet.";

  const UitgangspuntenControleMaandlastEnumOptions = [
    {
      label: "Benodigd inkomen voor hypotheek",
      value: ControleMaandlastOptions.BenodigdInkomen
    },
    {
      label: "Werkelijke last toets",
      value: ControleMaandlastOptions.WerkelijkeLast
    }
  ];

  const UitgangspuntenControleHypotheeknormOptions = [
    {
      label: "Netto besteedbaar inkomen",
      value: ControleGewenstInkomenOptions.NettoBesteedbaarInkomen
    },
    {
      label: "Bruto inkomenswens",
      value: ControleGewenstInkomenOptions.BrutoInkomenswens
    },
    {
      label: "Geen",
      value: ControleGewenstInkomenOptions.Geen
    }
  ];

  const UitgangspuntenControleGewenstInkomenOptions = [
    {
      label: "Behouden van huidige NBI",
      value: ControleGewenstNbiOptions.BehoudenHuidigNbi
    },
    {
      label: "Acceptabele marge op huidige NBI",
      value: ControleGewenstNbiOptions.MargeHuidigNbi
    },
    {
      label: "Overhouden van vast bedrag NBI",
      value: ControleGewenstNbiOptions.VastBedrag
    },
    {
      label: "Percentage van huidig NBI",
      value: ControleGewenstNbiOptions.PercentageHuidigNbi
    }
  ];

  const tonenConfig =
    values[namePrefix].controleGewenstInkomen.controleOpGewenstInkomen !== ControleGewenstInkomenOptions.Geen &&
    values[namePrefix].controleGewenstInkomen.controleOpGewenstInkomen !==
      ControleGewenstInkomenOptions.BrutoInkomenswens;

  return (
    <div>
      <Label name={namePrefix} caption={<h2>Controle op norm</h2>} tooltip={controleNormTooltip} />
      <div className="px-2">
        <CheckBox caption="Controle maandlast" name={`${namePrefix}.controleHypotheeknorm.controleMaandlast`} />

        {values[namePrefix].controleHypotheeknorm.controleMaandlast === true && (
          <div className="ml-3">
            <RadioInputGroup
              name={`${namePrefix}.controleHypotheeknorm.controleMaandlastEnumWaarde`}
              options={UitgangspuntenControleMaandlastEnumOptions}
            />
          </div>
        )}
        <CheckBox
          caption="Maximale hypotheeksom"
          name={`${namePrefix}.controleHypotheeknorm.controleerMaximaleHypotheek`}
        />
      </div>
      <div>
        <h2 className="ml-3">Controle op gewenst inkomen</h2>
        <LabeledRadioInput
          caption="Controle op gewenst inkomen"
          name={`${namePrefix}.controleGewenstInkomen.controleOpGewenstInkomen`}
          options={UitgangspuntenControleHypotheeknormOptions}
        />
        {values[namePrefix].controleGewenstInkomen.controleOpGewenstInkomen ===
          ControleGewenstInkomenOptions[ControleGewenstInkomenOptions.NettoBesteedbaarInkomen] && (
          <LabeledSelectInput
            caption="Gewenst netto besteedbaar inkomen"
            name={`${namePrefix}.controleGewenstInkomen.controleGewenstNbi`}
            options={UitgangspuntenControleGewenstInkomenOptions}
          />
        )}

        {tonenConfig &&
          values[namePrefix].controleGewenstInkomen.controleGewenstNbi ===
            ControleGewenstNbiOptions[ControleGewenstNbiOptions.MargeHuidigNbi] && (
            <>
              <LabeledPercentageInput
                caption="Acceptabele marge op huidig NBI"
                name={`${namePrefix}.controleGewenstInkomen.margeBovengrensPercentage`}
                decimalen={0}
                appendChildren={
                  <div className="d-flex align-items-center ml-2">
                    <span>Bovengrens</span>
                  </div>
                }
              />
              <LabeledPercentageInput
                caption=""
                name={`${namePrefix}.controleGewenstInkomen.margeOndergrensPercentage`}
                decimalen={0}
                appendChildren={
                  <div className="d-flex align-items-center ml-2">
                    <span>Ondergrens</span>
                  </div>
                }
              />
            </>
          )}

        {values[namePrefix].controleGewenstInkomen.controleOpGewenstInkomen ===
          ControleGewenstInkomenOptions[ControleGewenstInkomenOptions.NettoBesteedbaarInkomen] && (
          <div className="form-group form-row">
            <div className={"col-7"}>
              <Label caption="Selecteer de gewenste tabellen voor het toelichten van het netto besteedbaar inkomen." />
            </div>
            <div className={"col-5"}>
              <CheckBox caption="Gewijzigde jaren" name={`${namePrefix}.controleGewenstInkomen.gewijzigdeJarenTonen`} />
              <CheckBox
                caption="Benodigd kapitaal"
                name={`${namePrefix}.controleGewenstInkomen.benodigdKapitaalTonen`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ControleNormEnGewenstInkomen;
