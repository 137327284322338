import { LabelValuePairs } from "adviesbox-shared/utils/types";
import React, { ReactElement, useCallback, useEffect } from "react";
import { RapportageElementenType, RapportageElementSoort } from "./infra/rapportage-structuur-types";
import { RapportageStructuurElementen } from "./rapportage-structuur-elementen";
import classes from "./rapportage-structuur.module.scss";

export type RapportageStructuurProps = {
  elementen: RapportageElementenType[];
  parentKey: number;
  parent: string;
};

export const RapportageStructuur = ({ elementen, parent, parentKey }: RapportageStructuurProps): ReactElement => {
  const flattened: LabelValuePairs = [];

  const flattenElements = useCallback(
    (e: RapportageElementenType[], parent, layer = 0): void => {
      e.forEach((el, i) => {
        if (
          el.soortElement === RapportageElementSoort.Onderdeel ||
          el.soortElement === RapportageElementSoort.Hoofdstuk
        ) {
          flattened.push({
            value: `${parent}.elementen[${i}]`,
            label: "- ".repeat(layer) + el.naam
          });

          flattenElements(el.elementen, `${parent}.elementen[${i}]`, layer + 1);
        }
      });
    },
    [flattened]
  );

  useEffect(() => {
    flattenElements(elementen, parent);
  }, [flattenElements, elementen, parent]);

  return (
    <div className={classes.structuur_table}>
      {elementen.map((element: RapportageElementenType, i: number) => {
        return (
          <RapportageStructuurElementen
            element={element}
            key={`${parent}.elementen[${i}]`}
            parentKey={parentKey}
            parent={`${parent}.elementen[${i}]`}
            adoptionList={flattened}
          />
        );
      })}
    </div>
  );
};
